import moment from 'moment-timezone'
import cx from 'classnames'
import { DATE_FORMAT } from '~constants'
import styles from '../../../ShowsCalendar.module.scss'
import ViewButtonGroup from 'components/modules/ShowsCalendar/elements/ViewButtonGroup'
import { Heading } from '~elements'
import NextButton from '../NextButton/NextButton'
import PrevButton from '../PrevButton/PrevButton'

interface MonthViewNavProps {
  activeView: string
  calendarViews?: Array<string>
  hideNavTitle?: boolean
  month?: moment.Moment
  nextMonth: () => void
  position: string | 'top' | 'bottom'
  prevMonth: () => void
  seasonEndDate?: string | null | undefined
  seasonStartDate?: string | null | undefined
  setActiveView: (view: string) => void
  setHasViewChanged: (status: boolean) => void
  disabled: boolean
}

const MonthViewNav = ({
  hideNavTitle,
  month,
  nextMonth,
  position,
  prevMonth,
  activeView,
  calendarViews,
  setActiveView,
  setHasViewChanged,
  seasonEndDate,
  seasonStartDate,
  disabled
}: MonthViewNavProps) => {
  const seasonStartMonth = moment(seasonStartDate, DATE_FORMAT.SHORT_DATE_FORMAT).startOf('month')
  const seasonEndMonth = moment(seasonEndDate, DATE_FORMAT.SHORT_DATE_FORMAT).endOf('month')
  const currentMonth = month
    ? month.format(`${DATE_FORMAT.SHORT_MM}-${DATE_FORMAT.SHORT_YEAR}`)
    : moment().format(`${DATE_FORMAT.SHORT_MM}-${DATE_FORMAT.SHORT_YEAR}`)
  const seasonStartMonthStr = seasonStartMonth.format(`${DATE_FORMAT.SHORT_MM}-${DATE_FORMAT.SHORT_YEAR}`)
  const seasonEndMonthStr = seasonEndMonth.format(`${DATE_FORMAT.SHORT_MM}-${DATE_FORMAT.SHORT_YEAR}`)
  const hasPrevMonth = currentMonth !== seasonStartMonthStr
  const hasNextMonth = currentMonth !== seasonEndMonthStr
  const isBeforeCurrentMonth = month?.isBefore(moment())

  const prevMonthName = moment(currentMonth, `${DATE_FORMAT.SHORT_MM}-${DATE_FORMAT.SHORT_YEAR}`).subtract(1, 'month').format('MMMM')
  const nextMonthName = moment(currentMonth, `${DATE_FORMAT.SHORT_MM}-${DATE_FORMAT.SHORT_YEAR}`).add(1, 'month').format('MMMM')

  return (
    <>
      <div className={cx([styles['calendar__month-navs'], styles[`calendar__month-navs--${position}`]])}>
        <div
          className={cx([
            styles['calendar__month-navs'],
            styles[`calendar__month-navs--${position}`],
            {
              calendar__month_navs_hide: hideNavTitle
            }
          ])}
        >
          {!hideNavTitle && month && (
            <div className={styles['calendar__current-month-year-title']} id="calendarMonthTitle">
              <h2>{month.format(`${DATE_FORMAT.MONTH_FULL_NAME} ${DATE_FORMAT.YEAR}`)}</h2>
            </div>
          )}
          <div
            className={cx([
              styles['calendar__month-nav'],
              styles['calendar__month-nav--prev'],
              {
                calendar__month_navs_hide: hideNavTitle
              },
              `${isBeforeCurrentMonth && styles['calendar__month-nav--prev-disabled']}`
            ])}
            onClick={isBeforeCurrentMonth ? undefined : () => prevMonth()}
          >
            <PrevButton disabled={isBeforeCurrentMonth!} clickCallback={prevMonth} />
            {hideNavTitle && <Heading level={2}>{prevMonthName}</Heading>}
          </div>
          <div
            className={cx([
              styles['calendar__month-nav'],
              styles['calendar__month-nav--next'],
              `${hideNavTitle && styles['calendar__month_navs_hide']}`,
              `${disabled && styles['calendar__month-nav--next-disabled']}`
            ])}
            onClick={disabled ? undefined : () => nextMonth()}
          >
            {hideNavTitle && <Heading level={2}>{nextMonthName}</Heading>}
            <NextButton disabled={disabled} clickCallback={nextMonth} />
          </div>
        </div>
        {!hideNavTitle && calendarViews && (
          <ViewButtonGroup options={calendarViews} activeView={activeView} setActiveView={setActiveView} setHasViewChanged={setHasViewChanged} />
        )}
      </div>
    </>
  )
}

export default MonthViewNav
