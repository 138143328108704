import moment from 'moment-timezone'
import React from 'react'
import { Button, Heading, Paragraph } from '~elements'
import { useRouter } from 'next/router'
import { gaBtnClick, gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
import { isProd } from '~constants'
import { EventDateProps } from '~types'

interface CalendarModalContentProps {
  confirmationModalButtonCtaText: string
  eventInfo: EventDateProps
  soldOutConfirmationModalText: { html: string }
  businessUnit?: string
}

const CalendarModalContent = ({
  eventInfo,
  eventInfo: { event_date, price, host_url, formatted_start_time, name, is_sold_out, hidePricing, spanMultipleDays, end_date },
  confirmationModalButtonCtaText,
  businessUnit
}: CalendarModalContentProps) => {
  const { query } = useRouter()
  const requestCameFrom = query.camefrom || 'cfc_sphere'
  const { min: minPrice, max: maxPrice } = price
  const eventPriceRange = minPrice === maxPrice ? `$${minPrice}` : `$${minPrice} - $${maxPrice}`

  const localeTimestamp = moment(event_date)
  const endTimestamp = moment.utc(end_date)
  const formattedEventDay = localeTimestamp.format('dddd')
  const formattedEndDay = moment.utc(end_date).format('dddd')
  const formattedEventDate = localeTimestamp.format('MMMM DD, YYYY')

  const [source] = query.slug as string[]
  const handleBtnOnClick = () => {
    const currentEvent: any = eventInfo

    gaBuyTicketsClick({
      businessUnit,
      buyType: '',
      clickSource: source ?? '',
      clickTracking: '',
      eventDate: moment(currentEvent.date_time).format('MM/DD/YYYY'),
      eventVenueName: 'sphere_vegas',
      eventCategory: currentEvent.category.length > 0 ? currentEvent.category[0].value : '',
      eventType: currentEvent.category.length > 0 ? currentEvent.category[0].value : '',
      eventDataStatus: currentEvent.sales_status,
      linkLocation: CalendarModalContent.displayName,
      linkPosition: '',
      ticketmasterEventId: currentEvent.id,
      cmsIdentifier: '',
      buttonPlacementDescription: ''
    })
  }

  let dateString
  if (localeTimestamp.isSame(endTimestamp, 'month') && localeTimestamp.isSame(endTimestamp, 'year')) {
    dateString = `${localeTimestamp.format('MMMM')} ${localeTimestamp.format('DD')} - ${endTimestamp.format('DD')}, ${localeTimestamp.format('YYYY')}`
  } else if (localeTimestamp.isSame(endTimestamp, 'year')) {
    dateString = `${localeTimestamp.format('MMMM')} ${localeTimestamp.format('DD')} - ${endTimestamp.format('MMMM')} ${endTimestamp.format(
      'DD'
    )}, ${localeTimestamp.format('YYYY')}`
  } else {
    dateString = `${localeTimestamp.format('MMMM DD, YYYY')} - ${endTimestamp.format('MMMM DD, YYYY')}`
  }

  return (
    <>
      <Heading level={6}>Showtime selected</Heading>
      {spanMultipleDays ? (
        <Heading level={2}>
          <>
            {formattedEventDay} - {formattedEndDay}
            <br />
            {dateString}
            <br />
            {formatted_start_time} PST
          </>
        </Heading>
      ) : (
        <Heading level={2}>
          <>
            {formattedEventDay}
            <br />
            {formattedEventDate}
            <br />
            {formatted_start_time} PST
          </>
        </Heading>
      )}
      {price && !is_sold_out && !hidePricing && price.min && price.max ? (
        <p className="price">
          Tickets from{' '}
          <span>
            {eventPriceRange}
            <sup>*</sup>
          </span>
        </p>
      ) : null}

      <p>Ticket purchase available through Ticketmaster.</p>
      <Button
        asLink
        id={isProd ? 'buy-tickets-btn-calendar-ga' : ''}
        color="primary"
        fill="solid"
        isTargetBlank
        displayLinkInline
        justify="center"
        onClick={handleBtnOnClick}
        size="md"
        title={confirmationModalButtonCtaText}
        url={`${host_url}?camefrom=${requestCameFrom}`}
      />
    </>
  )
}
CalendarModalContent.displayName = 'Calendar Modal Content'

export default CalendarModalContent
