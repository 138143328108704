import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './GroupTicketingCard.module.scss'
import { Col, Row } from 'react-grid-system'
import { Button, Heading, Paragraph } from '~elements'
import { ButtonProps, GroupTicketingCardProps } from '~types'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
import { useRouter } from 'next/router'
import { useWindowSize } from 'utils/hooks'
import { getBackgroundGradientStyle } from 'utils'

const GroupTicketingCard = ({
  media,
  headline,
  eyebrow,
  eyebrowColor,
  description,
  backgroundGradient,
  backgroundColor,
  buttonGroup,
  hasMediaInRightColumn,
  groupTicketingCardId,
  heading,
  headlineIsCenterAligned = false,
  eyebrowIsCenterAligned = false,
  descriptionIsCenterAligned = false,
  buttonGroupIsCenterAligned = false
}: GroupTicketingCardProps) => {
  const groupTicketingCardStyles = cx([styles['group-ticketing'], styles[`has-media-in-${hasMediaInRightColumn ? 'right' : 'left'}-col`]])
  const contentWrapperStyles = cx([
    styles['col-wrapper--content'],
    styles[headlineIsCenterAligned && eyebrowIsCenterAligned && descriptionIsCenterAligned ? 'center-aligned' : '']
  ])
  const buttonContainerStyles = cx([styles['btn-container'], styles[buttonGroupIsCenterAligned ? 'center-aligned' : '']])

  const router = useRouter()
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const [backgroundStyle, setBackgroundStyle] = useState(() =>
    getBackgroundGradientStyle(backgroundGradient, backgroundColor?.hex, isBelowBreakpointMd)
  )

  useEffect(() => {
    const newStyle = getBackgroundGradientStyle(backgroundGradient, backgroundColor?.hex, isBelowBreakpointMd)
    setBackgroundStyle(newStyle)
  }, [isBelowBreakpointMd, backgroundGradient, backgroundColor])

  const handleButtonClick = (buttonProps: ButtonProps) => {
    if (buttonProps.url) {
      router.push(buttonProps.url)
    } else {
      gaBtnClick(CTA_CLICK_EVENT, headline || '', buttonProps.title)
    }
  }

  return (
    <div className={styles['container']} id={groupTicketingCardId}>
      {heading ? (
        <Heading level={2} justify="center">
          {heading}
        </Heading>
      ) : null}
      <Row className={groupTicketingCardStyles} nogutter={true} direction={hasMediaInRightColumn ? 'row-reverse' : 'row'}>
        <Col lg={6}>
          <div className={styles['col-wrapper--media']}>
            {media && (
              <div className={styles['media-column']} key={media.id}>
                {media.componentName === 'Image' && media.image ? (
                  <>
                    <div className={styles['media-image']} style={{ backgroundImage: `url(${media.image?.url})` }} />
                    <div className={styles['media-overlay']} />
                  </>
                ) : (
                  <div className={styles['video-container']}>
                    <video
                      id="background-video"
                      loop
                      muted
                      autoPlay={true}
                      width={'100%'}
                      poster={media.posterImage[0].url}
                      disableRemotePlayback
                      controls={false}
                      playsInline={true}
                    >
                      <source src={media.video[0].url} type="video/mp4" />
                    </video>
                    <div className={styles['media-overlay']} />
                  </div>
                )}
              </div>
            )}
          </div>
        </Col>
        <Col lg={6} className="align-contents-vertical">
          <div className={`${styles['gradient-block']}`} style={{ background: backgroundStyle }}>
            <div className={contentWrapperStyles} id={groupTicketingCardId}>
              <div className={styles['text-content']}>
                {headline && (
                  <Heading level={4} justify={headlineIsCenterAligned ? 'center' : 'left'}>
                    <>{headline}</>
                  </Heading>
                )}
                {eyebrow && (
                  <Heading level={6} levelDisplay={'eyebrow'} color={eyebrowColor} justify={eyebrowIsCenterAligned ? 'center' : 'left'}>
                    {eyebrow}
                  </Heading>
                )}
                {description?.html && <Paragraph text={description} justify={descriptionIsCenterAligned ? 'center' : 'left'} />}
              </div>
              {buttonGroup && buttonGroup.length > 0 && (
                <div className={buttonContainerStyles}>
                  {buttonGroup.map((buttonProps, index) => (
                    <Button key={`${buttonProps.title}_${index}`} {...buttonProps} onClick={() => handleButtonClick(buttonProps)} />
                  ))}
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default GroupTicketingCard
