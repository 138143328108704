import router from 'next/router'
import Link from 'next/link'
import { Heading, PortalModal, Form, Button } from '~elements'
import styles from './ExperienceCard.module.scss'
import { ButtonProps } from '~types'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
import parse from 'html-react-parser'
import { useState } from 'react'

interface ExperienceCardProps {
  backgroundImage: { url: string }
  imageRedirectUrl?: string
  buttonGroup?: Array<ButtonProps>
  headline?: string
  eyebrow?: string
  subheadline?: string
  description?: { html: string }
}

const ExperienceCard = ({ backgroundImage, buttonGroup, headline, eyebrow, description, imageRedirectUrl }: ExperienceCardProps) => {
  const [showSignUpModal, setShowSignUpModal] = useState(false)
  const [modalFormId, setModalFormId] = useState('')

  const handleButtonClick = (buttonProps: ButtonProps) => {
    if (buttonProps.modalFormId) {
      setShowSignUpModal(true)
      setModalFormId(buttonProps.modalFormId)
    } else if (buttonProps.url) {
      router.push(buttonProps.url)
    } else {
      gaBtnClick(CTA_CLICK_EVENT, headline || '', buttonProps.title)
    }
  }

  const closeModalHandler = () => {
    setShowSignUpModal(false)
    setModalFormId('')
  }

  const formSubmitHandler = () => {
    setShowSignUpModal(false)
    setModalFormId('')
  }

  return (
    <>
      <div className={styles['experience-card']}>
        <div className={styles['image-container']} style={{ cursor: imageRedirectUrl ? 'pointer' : 'default' }}>
          {backgroundImage && (
            <div>
              {imageRedirectUrl ? (
                <Link href={imageRedirectUrl} target="_blank" passHref>
                  <img alt={headline} src={backgroundImage.url} />
                </Link>
              ) : (
                <img alt={headline} src={backgroundImage.url} />
              )}
            </div>
          )}
        </div>
        <div className={styles['experience-card-content']}>
          <div className={styles['experience-card-content__inner']}>
            {headline && <Heading level={2}>{headline}</Heading>}
            {eyebrow && (
              <Heading level={6} levelDisplay={'eyebrow'} color={'gold'}>
                {eyebrow}
              </Heading>
            )}
            {description && <p>{parse(description.html)} </p>}
          </div>
        </div>
        {buttonGroup && buttonGroup.length > 0 && (
          <div className={styles['btn-container']}>
            {buttonGroup.map((buttonProps, index) => (
              <Button key={`${buttonProps.title}_${index}`} {...buttonProps} onClick={() => handleButtonClick(buttonProps)} />
            ))}
          </div>
        )}
      </div>
      <PortalModal isOpen={showSignUpModal} handleModalClose={closeModalHandler} closeOnOutsideClick={false}>
        <Form formId={modalFormId} formSubmitHandler={formSubmitHandler} isChildOfModal />
      </PortalModal>
    </>
  )
}

export default ExperienceCard
