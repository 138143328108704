import React, { MouseEvent, useState } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'
import cx from 'classnames'
import { useWindowSize } from 'utils/hooks'
import { CalendarNavProps } from 'types/calendar'
import styles from '../ShowsCalendar.module.scss'
import moment from 'moment'

interface MonthOption {
  label: string
  shortLabel: string
  value: string
}

const CalendarNav = ({ calendarFilterItems, selectedMonth, handleMonthNavChange, loadCalendarData, activeMonthList }: CalendarNavProps) => {
  const parseDate = (dt: string, format: string) => moment(dt, 'MMM-YYYY').format(format)
  const [isNavOpen, showIsNavOpen] = useState(false)
  const toggleOptions = () => showIsNavOpen(!isNavOpen)
  const { isBreakpoint: isBelowBreakpointMd } = useWindowSize('md', '<')
  const dropdownSelectedField = isBelowBreakpointMd ? 'shortLabel' : 'label'
  const monthOptions = activeMonthList.map(x => ({
    label: parseDate(x, 'MMMM YYYY'),
    shortLabel: parseDate(x, 'MMM YYYY'),
    value: parseDate(x, 'MMMM-YYYY')
  }))
  monthOptions.unshift({
    label: 'Select Month',
    shortLabel: 'Select Month',
    value: 'all-dates'
  })

  const setActiveMonth = (event: MouseEvent<HTMLLIElement>) => {
    const text = (event.target as HTMLLIElement).innerText
    const [selectedMonth] = monthOptions.filter(month => month[dropdownSelectedField] === text)
    const parsedDate = moment(selectedMonth.label, 'MMMM YYYY')
    const currentMonth = moment(parsedDate).startOf('month')
    showIsNavOpen(false)
    handleMonthNavChange(selectedMonth)
    loadCalendarData(currentMonth)
  }

  const closeAllOptions = () => showIsNavOpen(false)

  return (
    <div className={styles['calendar-nav']}>
      <OutsideClickHandler
        onOutsideClick={() => {
          closeAllOptions()
        }}
      >
        <div className={styles['calendar-form-input']}>
          <div onClick={() => toggleOptions()} className={cx([styles['options-toggler']])}>
            {selectedMonth.label}
            <div className={cx([styles['caret'], styles[isNavOpen ? '' : 'is-open']])} />
          </div>
          {isNavOpen && calendarFilterItems.length && (
            <div className={styles['options-container']}>
              <ul>
                {monthOptions.map((month: MonthOption, i: number) => (
                  <li
                    className={cx([styles['option'], styles[selectedMonth[dropdownSelectedField] == month[dropdownSelectedField] ? 'active' : '']])}
                    data-id={month.value}
                    key={i}
                    onClick={e => setActiveMonth(e)}
                  >
                    {month[dropdownSelectedField]}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  )
}

export default CalendarNav
