import {
  ANALYTICS_PAGE_LOAD_DATA,
  PAGE_TYPE_HOME,
  ANALYTICS_EVENT_BUTTON_CLICK,
  ANALYTICS_LINK_LOCATION_FOOTER,
  ANALYTICS_LINK_MODULE_CTA
} from '~analyticsConstants'

export const tagManagerArgs = {
  gtmId: 'GTM-MLJ3V4L'
}

export const adobeAnalytics = process.env.NEXT_PUBLIC_ADOBE_LIBRARY_URL

export const getPageType = (slug: string) => {
  return slug === '/' ? PAGE_TYPE_HOME : slug.substring(1)
}

const setPageData = (pageType: string) => {
  return Object.assign({}, ANALYTICS_PAGE_LOAD_DATA, { page_type: pageType })
}

//  @param eventType comes from constants, examples - 'page-load', 'button-click' etc
//  @param pageType is the name of the page, can be extracted from slug via getPageType function
//  @param linkData is an object that should include:
//  1. linkId - the text value of the item clicked ex: 'about'
//  2. linkModule - the type of the item clicked ex: 'global-navigation'
//  3. linkLocation - the area on the page of the item clicked ex: 'header'
export const fireAnalytics = (eventType: string, pageType: string, linkData?: object) => {
  const data = {
    isTrusted: false,
    detail: {
      ...linkData,
      pageData: setPageData(pageType)
    },
    type: eventType
  }

  document.body.dispatchEvent(new CustomEvent(eventType, data))
}

// helper function that takes in a string and strips special characters and spaces, then joins with the delimiter
// @param text String
// @param delimeter Delimeter to join the text with
export const formatAnalyticsText = (text: string, delimeter = '-') =>
  typeof text === 'string' &&
  text
    .replace(/[^\w\s-]|-\s/gi, '')
    .replace(/\s+/g, delimeter)
    .toLowerCase()

export const setLinkId = (commonData: object, text: string, type?: string) => {
  return Object.assign({}, commonData, { linkId: type ? `${type}:${formatAnalyticsText(text)}` : formatAnalyticsText(text) })
}

export const setCareerFormLinkId = (categoryText: string, locationText: string) => {
  return `category:${formatAnalyticsText(categoryText)}:location:${formatAnalyticsText(locationText)}:view-jobs`
}

export const analyticsClickHandler = (title: string, asPath: string) => {
  fireAnalytics(ANALYTICS_EVENT_BUTTON_CLICK, getPageType(asPath), {
    linkId: formatAnalyticsText(title),
    linkModule: ANALYTICS_LINK_MODULE_CTA,
    linkLocation: ANALYTICS_LINK_LOCATION_FOOTER
  })
}
