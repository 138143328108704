import { Container } from 'react-grid-system'
import parse from 'html-react-parser'

import { Button } from '~elements'
import { ButtonProps } from '~types'

import styles from './NotificationBanner.module.scss'

interface NotificationBannerProps {
  copy: { html: string }
  ctaPrimary?: ButtonProps
  showBanner: boolean
}

const NotificationBanner = ({ copy, ctaPrimary, showBanner }: NotificationBannerProps) =>
  showBanner ? (
    <div className={styles['notification-banner']}>
      <div className={styles.container}>
        <div className={styles['notification_banner__content']}>
          <div className={styles['notification_banner__text']}>{copy?.html && parse(copy.html)}</div>
          {ctaPrimary && (
            <div className={styles['notification_banner__button']}>
              <Button {...ctaPrimary} />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className={styles['notification-banner']} />
  )

export default NotificationBanner
