import React, { useRef, useState, useEffect } from 'react'
import { useIsIntersecting } from 'utils/hooks/useIsIntersecting'
import { useWindowSize } from 'utils/hooks'
import styles from './IconButtonsCard.module.scss'
import cx from 'classnames'
import { IconButtonsCardProps } from '~types'
import Image from 'next/image'
import { Heading } from '~elements'

const IconButtonsCard = ({ eyebrow, headline, backgroundImage, backgroundVideo, iconButtons }: IconButtonsCardProps) => {
  const { isBreakpoint: isBelowBreakpointLg } = useWindowSize('lg', '<')

  const hoverRef = useRef(null)
  const [hasAnimationPlayed, setHasAnimationPlayed] = useState(false)
  const isElementIntersecting = useIsIntersecting({
    ref: hoverRef,
    observerOptions: {
      threshold: [0.2]
    }
  })

  useEffect(() => {
    if (isElementIntersecting && !hasAnimationPlayed) {
      setHasAnimationPlayed(true)
    }
  }, [isElementIntersecting, hasAnimationPlayed])
  const enabledButtonsCount = iconButtons.filter(btn => btn.enable).length

  const getDelayClass = (index: number) => {
    if (enabledButtonsCount >= 7 && index >= 4) return styles['delay-long']
    if (enabledButtonsCount <= 6 && index >= 3) return styles['delay-long']
    if (enabledButtonsCount === 4) return styles['delay-long']

    return styles['delay-short']
  }

  const getLayoutClass = (numButtons: number) => {
    if (isBelowBreakpointLg) {
      return styles['two-per-row']
    }
    if (numButtons >= 7 || numButtons === 4) {
      return styles['four-per-row']
    }
    return styles['three-per-row']
  }
  const layoutClass = getLayoutClass(enabledButtonsCount)

  return (
    <div className={styles['icon-buttons-card']} ref={hoverRef}>
      <video className={styles['background-video']} autoPlay={true} playsInline={true} muted loop controls={false}>
        <source src={backgroundVideo?.url} type="video/mp4" />
        <Image width="80" height="80" alt="video" src={backgroundImage.url} title="Your browser does not support the <video> tag" />
      </video>
      <div className={styles['content-wrapper']}>
        <div className={cx(styles['eyebrow'], hasAnimationPlayed ? styles['animate'] : '')}>
          <Heading level="eyebrow" color="turquoise">
            <span className={styles['span']}>{eyebrow}</span>
          </Heading>
        </div>
        <div className={cx(styles['headline'], hasAnimationPlayed ? cx(styles['animate'], styles['delay-short']) : '')}>
          <Heading level="1">
            <span className={styles['span']}>{headline}</span>
          </Heading>
        </div>
        <div className={styles['cards-container']}>
          {iconButtons.map(
            (btn, index) =>
              btn.enable && (
                <div key={btn.id} className={cx(styles['icon-button'], layoutClass, hasAnimationPlayed ? styles['animate'] : '')}>
                  <a href={btn.url} rel="noreferrer" target={btn.openLinkInNewTab ? '_blank' : '_self'}>
                    <div className={cx(styles['hover-container'], hasAnimationPlayed ? styles['animate'] : '', getDelayClass(index))}>
                      <Image alt="icon" width="80" height="80" className={cx(styles['icon'], styles['delay-short'])} src={btn.icon.url} />
                      <span className={cx(styles['text'], hasAnimationPlayed ? styles['animate'] : '', styles['delay-long'])}>{btn.cta}</span>
                    </div>
                  </a>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  )
}

export default IconButtonsCard
