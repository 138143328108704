import React, { useEffect } from 'react'
import cx from 'classnames'
import styles from './CardMedia.module.scss'
import Image from 'next/image'
import { Col, Container, Row } from 'react-grid-system'
import { Button, Heading, Vimeo } from '~elements'
import { ImageProps, VimeoProps } from '~types'
import parse from 'html-react-parser'
import { gaBtnClick } from 'utils/hooks/gaBtnClick'
import { CTA_CLICK_EVENT } from '~analyticsConstants'
import { useRouter } from 'next/router'
import { getOffsetTopByElementId } from 'utils/hooks'

interface CardMediaProps {
  copy?: { html: string }
  ctaBtnUrl?: string
  ctaBtnText?: string
  ctaBtnIsTargetBlank?: boolean
  hasMediaInRightColumn?: boolean
  headline?: string
  isChildOfGrid?: boolean
  items: Array<CardMediaProps>
  mediaColumn: Array<ImageProps> | Array<VimeoProps>
  subheadline: string
  imgSubheadline: ImageProps
  cardMediaId: string
  zIndex: number
}

const CardMediaItem = ({ item, isLastChild }: { item: CardMediaProps; isLastChild: boolean }) => {
  const { copy, ctaBtnUrl, ctaBtnText, ctaBtnIsTargetBlank, hasMediaInRightColumn, headline, subheadline, imgSubheadline, mediaColumn, cardMediaId } =
    item

  const cardMediaStyles = cx([
    styles['card-media'],
    styles[isLastChild ? 'is-last-child' : ''],
    styles[mediaColumn && mediaColumn[0]?.posterImage ? 'has-vimeo-modal' : ''],
    styles[`has-media-in-${hasMediaInRightColumn ? 'right' : 'left'}-col`]
  ])

  const { asPath } = useRouter()
  const [, hash] = (asPath as string).split('#')

  useEffect(() => {
    const scrollToHashElement = () => {
      const elementId = hash?.replace('#', '')
      const elementToScroll = document.getElementById(elementId)

      if (!elementToScroll) return

      const offset = getOffsetTopByElementId(elementToScroll)
      window.scrollTo({
        top: offset,
        behavior: 'smooth'
      })
    }

    scrollToHashElement()
    window.addEventListener('hashchange', scrollToHashElement)
    return () => window.removeEventListener('hashchange', scrollToHashElement)
  })

  return (
    <Col sm={12}>
      <div id={cardMediaId} className={styles['scroll']}>
        <Row className={cardMediaStyles}>
          <Col lg={6}>
            <div className={styles['col-wrapper--media']}>
              {mediaColumn &&
                mediaColumn.map((media, i: number) => (
                  <div className={styles['media-column']} key={media.id}>
                    {media.componentName === 'Image' ? (
                      <div className={'image-container'}>
                        <Image alt={media.image.fileName} src={media.image.url} fill priority />
                      </div>
                    ) : (
                      <Vimeo {...media} />
                    )}
                  </div>
                ))}
            </div>
          </Col>
          <Col lg={6} className="align-contents-vertical">
            <div className={styles['col-wrapper--content']} id={cardMediaId}>
              {headline && (
                <Heading level={2}>
                  <>{headline}</>
                </Heading>
              )}
              {subheadline && (
                <Heading level={3} color="light">
                  {subheadline}
                </Heading>
              )}
              {/* currently not using Image component as width and height is different per img need to refactor */}
              {imgSubheadline && <img alt={imgSubheadline.alt} className={styles['img-subheadline']} src={imgSubheadline.image.url} loading="lazy" />}
              {copy?.html && parse(copy.html)}
              {ctaBtnText && ctaBtnUrl && (
                <Button
                  onClick={() => gaBtnClick(CTA_CLICK_EVENT, headline, ctaBtnText)}
                  color="light"
                  fill="solid"
                  title={ctaBtnText}
                  url={ctaBtnUrl}
                  isTargetBlank={ctaBtnIsTargetBlank}
                />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  )
}

/*
 * @TODO: revisit to determine if we need Card Grids
 * Added extra props below to render a single card in case an array of cards is recieved
 * @TODO zIndex is hack way to fix partnership page cardmedia
 */
const CardMedia = ({
  items,
  copy,
  ctaBtnUrl,
  ctaBtnText,
  ctaBtnIsTargetBlank,
  hasMediaInRightColumn,
  headline,
  mediaColumn,
  subheadline,
  imgSubheadline,
  cardMediaId,
  zIndex
}: CardMediaProps) => {
  const cardMediaItem = {
    copy,
    ctaBtnUrl,
    ctaBtnText,
    ctaBtnIsTargetBlank,
    hasMediaInRightColumn,
    headline,
    subheadline,
    imgSubheadline,
    mediaColumn,
    cardMediaId
  }

  return (
    <>
      {/* If an array of cards is recieved, maps the array, otherwise return a single card */}
      {items && items.length > 0 ? (
        items.map((item, index) => <CardMediaItem item={item} key={index} isLastChild={items.length - 1 === index} />)
      ) : (
        <Container className={'container container--narrow-xxl'} {...(zIndex === 0 && { style: { zIndex } })}>
          <CardMediaItem item={cardMediaItem} />
        </Container>
      )}
    </>
  )
}
export default CardMedia
