import { Suspense, useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import cx from 'classnames'
import { Heading } from '~elements'
import NextImage from 'next/image'
import _, { debounce } from 'lodash'

import { GridView, MonthView, TileView } from './views'
import { fetchShowsCalendarData } from './shows-calendar-services'
import { ShowsCalendarProps, EventDayProps } from 'types/calendar'
import styles from './ShowsCalendar.module.scss'
import CalendarModalContent from '../Calendar/CalendarModalContent/CalendarModalContent'
import { EventDateProps, EventProps, MonthProps } from '~types'
import { useIsTablet } from 'utils/hooks'
import ShowTimeModal from './elements/ShowTimeModal/ShowTimeModal'
import SlidingPanel from 'components/elements/SlidingPanel/SlidingPanel'

const ShowsCalendar = (props: ShowsCalendarProps) => {
  const {
    backgroundImage,
    heading,
    authorizationToken,
    eventEngineApiUrl,
    artistList,
    showCalendar = true,
    startDate = new Date(),
    confirmationModalButtonCtaText = 'Buy Tickets',
    defaultCalendarView = 'month',
    calendarViews = ['grid', 'month'],
    showsGridView,
    slidingPanel
  } = props ?? {}

  // @TODO following release pull out into a util
  const isLocalStorageAvailable = (): boolean => {
    try {
      const storage = window.localStorage
      const test = '__storage_test__'
      storage.setItem(test, test)
      storage.removeItem(test)
      return true
    } catch (e) {
      return false
    }
  }

  const [isReady, setIsReady] = useState(false)
  const [isBackgroundImagesReady, setIsBackgroundImagesReady] = useState(false)
  const [showSlidingWindow, setShowSlidingWindow] = useState(false)

  const isTablet = useIsTablet()
  const [activeView, setActiveView] = useState(() => {
    if (typeof window !== 'undefined') {
      const urlHash = window.location.hash
      if (urlHash === '#shows' && calendarViews.includes('grid')) {
        return 'grid'
      } else if (urlHash === '#showsCalendar' && calendarViews.includes('month')) {
        return 'month'
      } else if (isLocalStorageAvailable()) {
        const savedView = localStorage.getItem('activeCalendarView')
        if (savedView && calendarViews.includes(savedView)) {
          return savedView
        }
      }
      return defaultCalendarView || 'month'
    }
  })

  useEffect(() => {
    if (typeof window !== 'undefined' && isLocalStorageAvailable()) {
      const currentView = localStorage.getItem('activeCalendarView')
      if (currentView !== activeView) {
        localStorage.setItem('activeCalendarView', activeView)
      }
    }
  }, [activeView])

  useEffect(() => {
    const handleHashChange = () => {
      const urlHash = window.location.hash
      if (urlHash === '#shows' && calendarViews.includes('grid')) {
        setActiveView('grid')
      } else {
        setActiveView(defaultCalendarView || 'month')
      }
    }

    window.addEventListener('hashchange', handleHashChange, false)

    return () => {
      window.removeEventListener('hashchange', handleHashChange, false)
    }
  }, [defaultCalendarView, calendarViews])

  useEffect(() => {
    const ready = Promise.all(
      showsGridView.showsCards.map(showCard => {
        return new Promise(resolve => {
          const image = new Image()
          image.src = showCard.backgroundImage.url
          image.onload = () => {
            resolve(image)
          }
        })
      })
    )
    ready.then(() => setIsBackgroundImagesReady(true))
  }, [])

  const [hasViewChanged, setHasViewChanged] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [modalEventInfo, setModalEventInfo] = useState<EventProps | null>()

  const [calendarList, setCalendarList] = useState<Array<EventDayProps>>([])
  const [filteredCalendarList, setFilteredCalendarList] = useState<Array<EventDayProps>>()
  const [seasonStartDate, setSeasonStartDate] = useState<string | null>(null)
  const [seasonEndDate, setSeasonEndDate] = useState<string | null>(null)
  const [month, setMonth] = useState<moment.Moment>(moment(startDate, 'MMDDYYYY'))
  const [selectedDate, setSelectedDate] = useState<string | null>(null)
  const [calendarFilterItems, setCalendarFilterItems] = useState<Array<MonthProps>>([])
  const [filteredCalendarData, setFilteredCalendarData] = useState<Array<EventDateProps>>([])
  const [selectedMonth, setSelectedMonth] = useState<MonthProps | object>({})
  const [disabled, setDisabled] = useState(false)
  const [activeMonthList, setActiveMonthList] = useState([])

  const artistDetails = artistList.reduce((acc, field) => {
    acc[field?.artistId] = {
      ...field
    }
    return acc
  }, {})

  const handleDateSelection = useCallback(async (eventInfo: EventProps) => {
    setModalEventInfo(eventInfo)
    setHandleShowSlidingWindow(true)
    document.getElementsByTagName('html')[0].style.overflowY = 'hidden'
  }, [])

  useEffect(() => {
    if (!showSlidingWindow) {
      setModalEventInfo(null)
    }
  }, [showSlidingWindow])

  const loadCalendarData = async (startDate: moment.Moment = month) => {
    setIsReady(false)
    let calendarNewList: EventDayProps[]
    let seasonStartDate = null
    let seasonEndDate = null
    let calendarData = null
    let calendarFilterItems = null
    let selectedMonth = null
    const response = await fetchShowsCalendarData(eventEngineApiUrl, authorizationToken, startDate)

    setIsReady(true)

    if (response) {
      ;({ calendarList: calendarNewList, seasonEndDate, seasonStartDate, calendarData, calendarFilterItems, selectedMonth } = response)
      setDisabled(false)
      setCalendarList(calendarNewList)

      setFilteredCalendarList(calendarNewList)
      setSeasonStartDate(seasonStartDate)
      setSeasonEndDate(seasonEndDate)

      setSelectedDate(seasonStartDate)

      setCalendarFilterItems(calendarFilterItems)
      setSelectedMonth(selectedMonth)
      setMonth(startDate)

      if (response.meta && response.meta.activeMonthList && response.meta.activeMonthList.length > 0) {
        const endDate = moment(response.meta.activeMonthList[response.meta.activeMonthList.length - 1], 'MMM-YYYY')
        const isStartSameAsLastMonth = moment(startDate).isSame(endDate)
        setDisabled(isStartSameAsLastMonth)
        setActiveMonthList(response.meta.activeMonthList ?? [])
      }
    }
  }

  const debouncedLoadCalendarData = useCallback(debounce(loadCalendarData, 500), [])

  useEffect(() => {
    if (calendarList && calendarList.length === 0) {
      debouncedLoadCalendarData()
    }
    return () => debouncedLoadCalendarData.cancel()
  }, [calendarList, debouncedLoadCalendarData])

  const viewSharedProps = {
    calendarList: filteredCalendarList,
    handleDateSelection,
    month,
    seasonEndDate,
    seasonStartDate,
    selectedDate,
    activeView,
    setActiveView,
    calendarViews,
    setHasViewChanged,
    setMonth,
    setSelectedDate,
    artistDetails,
    loadCalendarData,
    disabled
  }

  const onCloseModalHandler = useCallback(() => setShowModal(false), [])

  const classNames = cx([styles['show-calendar'], styles[`calendar--${activeView}-view`]])

  const handleMonthNavChange = (value: MonthProps) => {
    setSelectedMonth(value)
  }

  const setHandleShowSlidingWindow = value => {
    setShowSlidingWindow(value)
  }

  const handleShowCalendarClick = () => {
    showSlidingWindow && setShowSlidingWindow(false)
    document.getElementsByTagName('html')[0].style.overflowY = 'scroll'
  }
  return (
    <>
      {showCalendar && (
        <div className={cx([styles['hero-container'], styles['has-single-heading'], styles['is-short']])} onClick={handleShowCalendarClick}>
          {heading && (
            <Heading level={1} justify="center">
              {heading}
            </Heading>
          )}

          <div className={classNames}>
            {!isReady || !isBackgroundImagesReady ? (
              <div className="loading-container">
                <NextImage alt="loader" src={'/assets/loader.gif'} priority width={75} height={75} />
              </div>
            ) : null}

            {isReady && isBackgroundImagesReady && filteredCalendarList && (
              <>
                {activeView === 'month' ? (
                  <>
                    {isTablet ? (
                      <TileView
                        {...props}
                        {...viewSharedProps}
                        handleMonthNavChange={handleMonthNavChange}
                        calendarFilterItems={calendarFilterItems}
                        selectedMonth={selectedMonth}
                        activeMonthList={activeMonthList}
                      />
                    ) : (
                      <MonthView {...props} {...viewSharedProps} />
                    )}
                  </>
                ) : activeView === 'grid' ? (
                  <GridView
                    {...props}
                    {...viewSharedProps}
                    showsCards={showsGridView.showsCards}
                    sectionHeadline={showsGridView.sectionHeadline}
                    experienceCards={showsGridView.experienceCards}
                  />
                ) : null}
              </>
            )}
          </div>
          <div className={styles['hero-container__bg-img']} style={{ backgroundImage: `url(${backgroundImage?.url})` }} />
        </div>
      )}
      <Suspense>
        <ShowTimeModal type="show-calendar" bgColor="gray" isOpen={showModal} handleModalClose={onCloseModalHandler} closeOnOutsideClick={false}>
          <CalendarModalContent eventInfo={modalEventInfo} confirmationModalButtonCtaText={confirmationModalButtonCtaText} />
        </ShowTimeModal>
      </Suspense>
      <SlidingPanel
        showSlidingWindow={showSlidingWindow}
        handleShowSlidingWindow={setHandleShowSlidingWindow}
        artistDetail={slidingPanel?.artistDetail}
        description={slidingPanel?.description}
        disclaimer={slidingPanel?.disclaimer}
        icon={slidingPanel?.icon}
        eventInfo={modalEventInfo}
        businessUnit={props.businessUnit}
      />
    </>
  )
}

export default ShowsCalendar
