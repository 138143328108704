import { Button, Divider, Heading } from '~elements'
import _, { debounce } from 'lodash'
import Image from 'next/image'
import shareicon from '../../../public/assets/share.svg'
import ticketmaster from '../../../public/assets/ticketmaster.svg'
import Link from 'next/link'
import { SlidingPanelProps } from 'types/calendar'
import styles from './SlidingPanel.module.scss'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { gaBuyTicketsClick } from 'utils/hooks/gaBtnClick'
import { useRouter } from 'next/router'
import { isProd } from '~constants'

const SlidingPanel = (props: SlidingPanelProps) => {
  const [disableBuyTicketsButton, setDisableBuyTicketsButton] = useState(true)
  const [activeButton, setActiveButton] = useState(null)
  const { query } = useRouter()
  const requestCameFrom = query.camefrom || 'cfc_sphere'

  const { artistDetail, description, disclaimer, icon, eventInfo, businessUnit } = props ?? {}

  const artistDetails = artistDetail.reduce((acc, field) => {
    acc[field?.artistId] = {
      ...field
    }
    return acc
  }, {})

  const artistId = eventInfo && eventInfo?.[0]?.['artists']?.[0]?.id
  const selectedArtist = artistDetails[artistId]

  const DATE_FORMATTER = {
    FULL_DATE: 'MMMM D, YYYY | ddd'
  }
  const eventTimestamp = eventInfo && moment.tz(eventInfo[0]['date_time'], 'America/Los_Angeles')
  const formattedDate = eventTimestamp?.format(DATE_FORMATTER.FULL_DATE)
  const [name, setName] = useState('')
  const [hostUrl, setHostUrl] = useState('')

  useEffect(() => {
    if (props) {
      setName('')
      setHostUrl('')
    }
  }, [props])

  const handleShowSlidingWindow = () => {
    props.handleShowSlidingWindow(false)
    setDisableBuyTicketsButton(true)
    setActiveButton(null)
    document.getElementsByTagName('html')[0].style.overflowY = 'scroll'
  }

  useEffect(() => {
    if (!props.showSlidingWindow) {
      setDisableBuyTicketsButton(true)
      setActiveButton(null)
    }
  }, [props.showSlidingWindow])

  const handleTimeButtonClick = (id, name, hostUrl) => {
    setActiveButton(id)
    setName(name)
    setHostUrl(hostUrl)
    setDisableBuyTicketsButton(false)
  }
  const [source] = query.slug as string[]

  const handleBuyTicketButtonOnClick = (buttonProps: any) => {
    const currentEvent = eventInfo.find((x: any) => x.id === activeButton)

    const snakeCaseHeadline = selectedArtist.artistName
      ? selectedArtist.artistName
          .split(' ')
          .map(x => x.toLocaleLowerCase())
          .join('_')
      : ''

    if (buttonProps.title.toLowerCase() === 'buy tickets') {
      gaBuyTicketsClick({
        businessUnit,
        buyType: buttonProps.buyType,
        clickSource: source ?? '',
        clickTracking: `${window.location.href} ${snakeCaseHeadline}:buy_tickets`,
        eventDate: moment(currentEvent.date_time).format('MM/DD/YYYY'),
        eventVenueName: 'sphere_vegas',
        eventCategory: currentEvent.category.length > 0 ? currentEvent.category[0].value : '',
        eventType: currentEvent.category.length > 0 ? currentEvent.category[0].value : '',
        eventDataStatus: currentEvent.sales_status,
        linkLocation: SlidingPanel.displayName,
        linkPosition: '',
        ticketmasterEventId: currentEvent.id,
        cmsIdentifier: buttonProps.cmsIdentifier,
        buttonPlacementDescription: buttonProps.buttonPlacementDescription
      })
    }
  }

  return (
    <>
      <div className={styles['slide-module']} style={props.showSlidingWindow ? { right: 0 } : { right: '-512px' }}>
        <div
          className={styles['module-content-image']}
          style={{
            backgroundImage: `url(${selectedArtist?.artistImage?.url})`
          }}
        >
          <FontAwesomeIcon icon={faXmark} style={{ fontSize: '10px' }} onClick={handleShowSlidingWindow} />
          {selectedArtist && (
            <Link href={selectedArtist?.edpUrl} target="_blank">
              <div className={styles['module-content-image-content']}>
                <div className={styles['share-icon']}>
                  <Image src={shareicon} alt="share" />
                </div>
                {selectedArtist?.artistName && <Heading level={4}>{selectedArtist?.artistName}</Heading>}
                {selectedArtist?.description.html && <p>{parse(selectedArtist?.description.html)} </p>}
              </div>
            </Link>
          )}
        </div>
        <div className={styles['bottom-module']}>
          <div className={styles['module-content-calender']}>
            <div className={styles['list-item-date-holder']}>
              <div className={styles['list-item__date']}>
                <span className={styles['list-item__formatted-date']}>{formattedDate}</span>
              </div>
            </div>

            <div className={styles['list-item-ctas']}>
              {eventInfo?.map(data => {
                return data ? (
                  <div key={data.id}>
                    <Button
                      color="light"
                      disabled={false}
                      fill={activeButton === data.id ? 'solid' : 'hollow'}
                      size="sm"
                      title={data.formatted_start_time}
                      onClick={() => handleTimeButtonClick(data?.id, data?.name, data?.host_url)}
                    />
                  </div>
                ) : null
              })}
            </div>
          </div>
          <Divider />
          <div className={styles['module-content-footer']}>
            {selectedArtist?.cta && selectedArtist?.cta.length > 0 && (
              <div className={styles['module-content-footer-buttons']}>
                {selectedArtist?.cta.map((buttonProps, index) => (
                  <Button
                    asLink
                    id={isProd ? 'buy-tickets-btn-calendar-ga' : ''}
                    key={`${buttonProps.title}_${index}`}
                    {...buttonProps}
                    displayLinkInline
                    isTargetBlank={buttonProps.title.toLowerCase() === 'buy tickets' ? true : false}
                    onClick={() => handleBuyTicketButtonOnClick(buttonProps)}
                    disabled={disableBuyTicketsButton && buttonProps.title.toLowerCase() === 'buy tickets' ? true : false}
                    url={buttonProps.title.toLowerCase() === 'buy tickets' ? `${hostUrl}?camefrom=${requestCameFrom}` : buttonProps?.url}
                  />
                ))}
              </div>
            )}
            <div className={styles['module-content-footer-text']}>{props.description?.html && parse(props.description?.html)}</div>
            <div className={`${styles['module-content-footer-disclaimer']} `}>
              {parse(disclaimer?.html)}
              <div className={styles['ticketmaster-icon']}>
                <Image src={ticketmaster} width="24px" height="12px" alt="ticketmaster" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.showSlidingWindow ? <div className={styles['overlay']} onClick={handleShowSlidingWindow}></div> : ''}
    </>
  )
}
SlidingPanel.displayName = 'sliding_panel'

export default SlidingPanel
